import { Link } from "react-router-dom";
import "./btn.css";

const PrimaryBtn = ({ link, btnName }) => {
  return (
    <>
      <div className="bbb">
        <Link to={link} className="pbtn">
          {btnName}
        </Link>
      </div>
    </>
  );
};

export default PrimaryBtn;
