import Image1 from "./1.jpg";
import Image2 from "./2.jpg";
import Image3 from "./3.jpg";
import Image4 from "./4.jpg";
import Image5 from "./5.jpg";
import Image6 from "./6.jpg";
import Image7 from "./7.jpg";
import Image8 from "./8.jpg";
import Image9 from "./9.jpg";
import Image10 from "./10.jpg";
import Image11 from "./11.jpg";
import Image12 from "./12.jpg";
import Image13 from "./13.jpg";
import Image14 from "./14.jpg";
import Image15 from "./15.jpg";
import Image16 from "./16.jpg";
import Image17 from "./17.jpg";
import Image18 from "./18.jpg";
import Image19 from "./19.jpg";
import Image20 from "./20.jpg";
import Image21 from "./21.jpg";
import Image22 from "./22.jpg";
import Image23 from "./23.jpg";
import Image24 from "./24.jpg";
import Image25 from "./25.jpg";
import Image26 from "./26.jpg";
import Image27 from "./27.jpg";
import Image28 from "./28.jpg";
import Image29 from "./29.jpg";
import Image30 from "./30.jpg";
import Image31 from "./31.jpg";
import Image32 from "./32.jpg";
import Image33 from "./33.jpg";
import Image34 from "./34.jpg";
import Image35 from "./35.jpg";
import Image36 from "./36.jpg";
import Image37 from "./37.jpg";
import Image38 from "./38.jpg";
import Image39 from "./39.jpg";
import Image40 from "./40.jpg";
import Image41 from "./41.jpg";
import Image42 from "./42.jpg";
import Image43 from "./43.jpg";
import Image44 from "./44.jpg";
import Image45 from "./45.jpg";
import Image46 from "./46.jpg";

const galleryImages = [
  {
    img: Image31,
  },
  {
    img: Image32,
  },
  {
    img: Image1,
  },
  {
    img: Image2,
  },
  {
    img: Image7,
  },
  {
    img: Image8,
  },
  {
    img: Image3,
  },
  {
    img: Image4,
  },
  {
    img: Image9,
  },
  {
    img: Image5,
  },
  {
    img: Image6,
  },

  {
    img: Image10,
  },

  {
    img: Image12,
  },
  {
    img: Image11,
  },
  {
    img: Image13,
  },
  {
    img: Image14,
  },
  {
    img: Image15,
  },

  {
    img: Image17,
  },
  {
    img: Image16,
  },
  {
    img: Image18,
  },
  {
    img: Image19,
  },
  {
    img: Image20,
  },
  {
    img: Image21,
  },
  {
    img: Image22,
  },
  {
    img: Image23,
  },
  {
    img: Image24,
  },
  {
    img: Image25,
  },
  {
    img: Image26,
  },
  {
    img: Image27,
  },
  {
    img: Image28,
  },
  {
    img: Image29,
  },
  {
    img: Image30,
  },

  {
    img: Image33,
  },
  {
    img: Image34,
  },
  {
    img: Image35,
  },
  {
    img: Image36,
  },
  {
    img: Image37,
  },
  {
    img: Image38,
  },
  {
    img: Image39,
  },
  {
    img: Image40,
  },
  {
    img: Image41,
  },
  {
    img: Image42,
  },
  {
    img: Image43,
  },
  {
    img: Image44,
  },
  {
    img: Image45,
  },
  {
    img: Image46,
  },
];

export default galleryImages;
