import { Link } from "react-router-dom";
import { useState } from "react";
import "./NavBar.css";
import logo from "../../logos/logo.png";

const Navbar = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleLinkClick = () => {
    setIsChecked(false); // Close the navbar when a link is clicked
  };

  return (
    <nav>
      <Link to="/">
        <label className="logojbc">
          <img src={logo} alt="jbc logo" />
        </label>
      </Link>

      <input
        type="checkbox"
        id="check"
        checked={isChecked}
        onChange={() => setIsChecked(!isChecked)} // Toggle menu on checkbox change
      />
      <label htmlFor="check" className="checkbtn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="40px"
          viewBox="0 -960 960 960"
          width="40px"
          fill="#e8eaed"
        >
          <path d="M120-240v-80h720v80H120Zm0-200v-80h720v80H120Zm0-200v-80h720v80H120Z" />
        </svg>
      </label>
      <ul>
        <li>
          <Link to="/" onClick={handleLinkClick}>
            Home
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </Link>
        </li>
        <li>
          <Link to="/services" onClick={handleLinkClick}>
            Services
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </Link>
        </li>
        <li>
          <Link to="/gallery" onClick={handleLinkClick}>
            Gallery
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </Link>
        </li>
        <li>
          <Link to="/contact" onClick={handleLinkClick}>
            Contact
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
