import { SpeedInsights } from "@vercel/speed-insights/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./App.css";
import Hero from "./Components/Hero/Hero";
import Footer from "./Components/Footer/Footer";
import Gallery from "./Components/Gallery/Gallery";
import Contact from "./Components/MenuBar/Contact";
import Services from "./Components/MenuBar/Services";

import PageNotFound from "./Components/PageNotFound";
import Navbar from "./Components/NavBar/Navbar";
const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <div className="appWrapper">
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />

          {/* Add more routes as needed */}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
      <SpeedInsights />
    </BrowserRouter>
  );
};

export default App;
