import "./Home.css";
import PrimaryBtn from "../buttons/PrimaryBtn";

const Home = () => {
  return (
    <div>
      <div className="mainHero">
        <p className="para1">Welcome to</p>
        <div className="content">
          <h1>JBC Braids</h1>
          <p className="para2">Crafting Beauty... One Braid at a Time.</p>
          <PrimaryBtn link={"/services"} btnName={"Book Now"} />
        </div>
      </div>
    </div>
  );
};

export default Home;
