import { Link } from "react-router-dom";
import "./Menu.css";
import { useState, useEffect } from "react";

const Iframe = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Set a timeout to display the modal after 60 seconds (60000 ms)
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 5000);

    // Cleanup the timer when the component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleClick = () => {
    setIsVisible(false);
  };

  return (
    <div className="iframeWrap">
      {isVisible && (
        <div className="modalii">
          <div className="content">
            <p className="note">Please Note</p>
            <p className="text">
              Endeavor to come with your hair washed and blown out. <br />{" "}
              <br />
              Thanks.
            </p>
            <Link to="" className="continue" onClick={handleClick}>
              Continue
            </Link>
          </div>
        </div>
      )}
      <iframe
        src="https://app.acuityscheduling.com/schedule.php?owner=31344618"
        title="Schedule Appointment"
        width="100%"
        height="800"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default Iframe;
